import React from "react"
import Helmet from "react-helmet"

import ContentContainer from "../containers/ContentContainer"
import List from "../components/List"
import ListItem from "../components/ListItem"
import StandardContent from "../components/StandardContent"

const NotFound: React.FC<{}> = () => {
  return (
    <>
      <Helmet>
        <title>Not Found</title>
      </Helmet>

      <ContentContainer>
        <StandardContent>
          <h1>Page Not Found</h1>
          <p>The page you tried to access doesn't exist</p>
        </StandardContent>

        <List translucent={false} striped={false} borders={true} padded={true}>
          <ListItem text="Go to Home Page" link="/" indentLevel={0} />
        </List>
      </ContentContainer>
    </>
  )
}

export default NotFound
